"use client";

import {
  type StripCheckoutLinkResponse,
  type StripeCheckoutLinkRequest,
} from "@/app/api/stripe/route";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { toast } from "../ui/use-toast";
import { Button, type ButtonProps } from "../ui/button";
import { LoadingIcon } from "../ui/loading-icon";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import { Separator } from "../ui/separator";
import { StripeSubscriptionType, type Subscriptions } from "@prisma/client";
import { type StripeUpgradeSubscriptionRequest } from "@/app/api/stripe/upgrade/route";
import {
  enterpriseFeatures,
  subscriptionFeatures,
} from "@/utils/subscription/subscription-prices";
import { cn } from "@/lib/utils";
import { StripePlanType } from "@/utils/subscription/stripe";

const subscriptionPrices = {
  [StripeSubscriptionType.NoSubscription]: 0,
  [StripeSubscriptionType.Credits19dot99]: 19.99,
  [StripeSubscriptionType.Growth99]: 99,
};

function getLabel(plan: StripeSubscriptionType) {
  if (plan === StripeSubscriptionType.NoSubscription) {
    return "Free Plan";
  }
  if (plan === StripeSubscriptionType.Credits19dot99) {
    return "Starter Plan";
  }
  if (plan === StripeSubscriptionType.Growth99) {
    return "Growth Plan";
  }
}

function getPrice(plan: StripeSubscriptionType) {
  if (plan === StripeSubscriptionType.NoSubscription) {
    return "Free";
  }
  if (plan === StripeSubscriptionType.Credits19dot99) {
    return "$20/month";
  }
  if (plan === StripeSubscriptionType.Growth99) {
    return "$99/month";
  }
}

export function SubscriptionCard({
  plan,
  subscription,
}: {
  plan: StripeSubscriptionType;
  subscription: Subscriptions;
}) {
  const [fetching, setFetching] = useState(false);
  const router = useRouter();
  const features = subscriptionFeatures[plan];
  const isUpgrade =
    subscriptionPrices[plan] >
    subscriptionPrices[subscription.stripeSubscriptionType];

  function upgradeSubscription() {
    if (fetching) return;
    setFetching(true);

    if (plan === StripeSubscriptionType.NoSubscription) {
      void fetch("/api/stripe/cancel-subscription", {
        method: "POST",
      }).then((res) => {
        if (res.ok) {
          toast({
            title: "Subscription cancelled successfully",
            description: `Refresh the page in a bit...`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          toast({
            title: "Failed to cancel subscription, please try again later",
            variant: "destructive",
          });
        }
        setFetching(false);
      });

      return;
    }

    if (subscription.stripeSubscriptionId) {
      // Upgrade Flow
      void fetch("/api/stripe/upgrade", {
        method: "POST",
        body: JSON.stringify({
          type: plan,
        } as StripeUpgradeSubscriptionRequest),
      }).then((res) => {
        if (res.ok) {
          toast({
            title: "Subscription updated successfully",
            description: `Refresh the page in a bit...`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          toast({
            title: "Failed to checkout link, please try again later",
            variant: "destructive",
          });
        }
        setFetching(false);
      });
    } else {
      window?.localStorage?.setItem("stripe_redirect", window.location.href);

      let requestPlan = StripePlanType.Recurring19dot99;
      if (plan === StripeSubscriptionType.Growth99) {
        requestPlan = StripePlanType.Growth;
      } else if (plan === StripeSubscriptionType.Credits19dot99) {
        requestPlan = StripePlanType.Recurring19dot99;
      }

      void fetch("/api/stripe/", {
        method: "POST",
        body: JSON.stringify({
          type: requestPlan,
        } as StripeCheckoutLinkRequest),
      }).then(async (res) => {
        if (res.ok) {
          const { url } = (await res.json()) as StripCheckoutLinkResponse;
          router.push(url);
        } else {
          toast({
            title: "Failed to checkout link, please try again later",
            variant: "destructive",
          });
          setFetching(false);
        }
      });
    }
  }

  return (
    <div
      className={cn(
        "relative my-base flex w-[400px] flex-col gap-xl rounded-2xl border p-4 bg-white z-[2] text-text-landing dark:text-white overflow-hidden"
        // isFeatured ? "border-lavareachOG border-2" : ""
      )}
    >
      <div className="flex items-center">
        <div className="ml-4">
          <h2 className="text-base font-semibold leading-7">
            {getLabel(plan)} - {getPrice(plan)}
          </h2>
        </div>
      </div>

      <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />
      <ul className="flex flex-col gap-2 font-normal grow">
        {features.map((feature) => (
          <li
            key={feature}
            className="flex items-center gap-3 text-xs font-medium text-text-landing dark:text-white"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-text-landing dark:text-white"
            >
              <path
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="flex">{feature}</span>
          </li>
        ))}
      </ul>
      {subscription.stripeSubscriptionType === plan ? (
        <Button variant={"outline"} disabled>
          Current Plan
        </Button>
      ) : isUpgrade ? (
        <button
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2 group relative w-full gap-2 overflow-hidden text-lg font-semibold tracking-tighter transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2"
          onMouseDown={upgradeSubscription}
        >
          <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black"></span>

          <span className="text-sm">
            {fetching ? <LoadingIcon /> : `Upgrade to ${getLabel(plan)}`}
          </span>
        </button>
      ) : (
        <Button variant={"outline"} onMouseDown={upgradeSubscription}>
          {fetching ? <LoadingIcon /> : `Downgrade to to ${getLabel(plan)}`}
        </Button>
      )}
    </div>
  );
}

export function EnterpriseCard() {
  return (
    <div
      className={cn(
        "relative my-base flex w-full flex-col gap-xl rounded-2xl border p-4 bg-white z-[2] text-text-landing dark:text-white overflow-hidden"
        // isFeatured ? "border-lavareachOG border-2" : ""
      )}
    >
      <div className="flex gap-sm justify-between items-center">
        <div className="ml-4">
          <h2 className="text-base font-semibold leading-7">
            Enterprise - Custom Pricing Plan
          </h2>
        </div>
        <div className="w-100[px]">
          <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2 group relative w-full gap-2 overflow-hidden text-lg font-semibold tracking-tighter transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2">
            <a href="/schedule" target="__blank">
              <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black"></span>

              <span className="text-sm">Contact Us</span>
            </a>
          </button>
        </div>
      </div>
      <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />
      <ul className="grid grid-cols-3 gap-2 font-normal grow">
        {enterpriseFeatures.map((feature) => (
          <li
            key={feature}
            className="flex items-center gap-3 text-xs font-medium text-text-landing dark:text-white"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-text-landing dark:text-white"
            >
              <path
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="flex">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function ManageSubscriptionButton({
  variant,
  subscription,
  highlightOption,
}: {
  variant: ButtonProps["variant"];
  subscription: Subscriptions;
  highlightOption?: StripeSubscriptionType;
}) {
  const currentPlan = subscription.stripeSubscriptionType;

  const plansToShow = highlightOption
    ? [
        StripeSubscriptionType.NoSubscription,
        StripeSubscriptionType.Credits19dot99,
        StripeSubscriptionType.Growth99,
      ].filter((plan) => plan === highlightOption || plan === currentPlan)
    : [
        StripeSubscriptionType.NoSubscription,
        StripeSubscriptionType.Credits19dot99,
        StripeSubscriptionType.Growth99,
      ];

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={variant}>Manage Subscription</Button>
      </SheetTrigger>
      <SheetContent className="min-w-[900px]">
        <SheetHeader>
          <SheetTitle>Manage Subscription</SheetTitle>
          <Separator />
        </SheetHeader>
        <div className="py-2"></div>
        <div className="flex flex-row gap-xl">
          {plansToShow.map((plan) => (
            <SubscriptionCard
              key={plan}
              plan={plan}
              subscription={subscription}
            />
          ))}
        </div>
        {!highlightOption && <EnterpriseCard />}
      </SheetContent>
    </Sheet>
  );
}
