"use client";

import { type CreateMonitorRequest } from "@/app/api/list/monitor/route";
import { useUser } from "@clerk/nextjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { type AccountsMonitor, MonitorType } from "@prisma/client";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "../ui/use-toast";
import {
  CompactDialogBody,
  CompactDialogContent,
  CompactDialogFooter,
  CompactDialogHeader,
  Dialog,
  DialogTitle,
} from "../ui/dialog";
import { Separator } from "../ui/separator";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Label } from "../ui/label";
import { LoadingIcon } from "../ui/loading-icon";
import { Button } from "../ui/button";
import { ListSelector } from "./list-selector";
import { Delimiter, TagInput } from "../ui/tag-input";
import { type DeleteMonitorReq } from "@/app/api/list/monitor/delete/route";
import { type TestMonitorReq } from "@/app/api/list/monitor/test/route";
import Link from "next/link";
import { uniqueItems } from "@/lib/utils";

const monitorAccountSchema = z.object({
  email: z.array(z.string().email()),
  type: z.nativeEnum(MonitorType),
});

type MonitorAccountSchema = z.infer<typeof monitorAccountSchema>;

export function UpdateMonitorDialog({
  initialListId,
  initialData,
  open,
  availableMonitorTypes,
  setOpen,
  onCreate,
}: {
  initialListId?: string | undefined | null;
  initialData?: AccountsMonitor;
  open: boolean;
  availableMonitorTypes: MonitorType[];
  setOpen: (open: boolean) => void;
  onCreate?: () => void;
}) {
  const { user } = useUser();

  const [listId, setListId] = useState<string | undefined | null>(
    initialListId
  );

  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [testing, setTesting] = useState(false);

  const form = useForm<MonitorAccountSchema>({
    resolver: zodResolver(monitorAccountSchema),
    defaultValues: {
      email: initialData?.emailBack ?? [],
      type: initialData?.monitorType ?? MonitorType.RecentNews,
    },
    mode: "onChange",
  });

  useEffect(() => {
    const email = user?.emailAddresses[0]?.emailAddress;

    if (email) {
      const currentEmails = form.getValues("email");
      form.setValue("email", uniqueItems([email].concat(currentEmails)));
    }
  }, [user]);

  function createMonitorAccount(values: MonitorAccountSchema) {
    if (!listId) {
      toast({
        title: "Error",
        description: "Please select a list",
        variant: "destructive",
      });
      return;
    }
    if (creating) return;
    setCreating(true);

    void fetch("/api/list/monitor", {
      method: "POST",
      body: JSON.stringify({
        listId: listId,
        monitorType: values.type,
        email: values.email,
      } as CreateMonitorRequest),
    }).then((res) => {
      setCreating(false);
      onCreate?.();

      if (res.ok) {
        setOpen(false);
        toast({
          title: "Success",
          description: "Monitor setup successfully",
        });
      } else {
        toast({
          title: "Error",
          description: "Please try again later.",
          variant: "destructive",
        });
      }
    });
  }

  function testMonitor() {
    if (!listId) return;
    if (testing) return;
    setTesting(true);

    void fetch(`/api/list/monitor/test`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        testListId: listId,
        emailBack: form.getValues("email"),
      } as TestMonitorReq),
    }).then((res) => {
      setTesting(false);
      if (res.ok) {
        toast({ title: "Test Alert Sent" });
      } else {
        toast({ title: "Failed to send test alert" });
      }
    });
  }

  function deleteMonitor() {
    if (!initialData) return;
    if (deleting) return;
    setDeleting(true);

    void fetch("/api/list/monitor/delete", {
      method: "POST",
      body: JSON.stringify({
        monitorId: initialData.id,
      } as DeleteMonitorReq),
    }).then((res) => {
      setDeleting(false);

      if (res.ok) {
        setOpen(false);
        toast({
          title: "Success",
          description: "Monitor removed successfully",
        });

        window?.location.reload();
      } else {
        toast({
          title: "Error",
          description: "Please try again later.",
          variant: "destructive",
        });
      }
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <CompactDialogContent>
        <CompactDialogHeader>
          <DialogTitle className="mb-0">
            Configure Account Monitor {initialData ? "(Active)" : "(Inactive)"}
          </DialogTitle>
          <Link href="/dashboard/settings/monitors" target="__blank">
            <Label className="py-xs m-0 hover:underline hover:cursor-auto">
              View All Monitors
            </Label>
          </Link>
        </CompactDialogHeader>
        <Separator />
        <FormProvider {...form}>
          <form
            /* We have to keep this async here to process the form properly */
            /* eslint-disable-next-line  @typescript-eslint/no-misused-promises */
            onSubmit={form.handleSubmit(createMonitorAccount)}
          >
            <CompactDialogBody className="flex flex-col gap-sm">
              {initialListId == null && (
                <div className="grid grid-cols-2 items-center gap-lg">
                  <Label className="text-sm">List</Label>
                  <ListSelector
                    listId={listId ?? undefined}
                    setListId={setListId}
                  />
                </div>
              )}
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-2 items-center gap-lg">
                    <FormLabel className="text-left">Monitor Type</FormLabel>
                    <FormControl>
                      <Select
                        value={field.value}
                        onValueChange={(value) => field.onChange(value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Monitor Type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {availableMonitorTypes.map((item) => (
                              <SelectItem key={item} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="col-span-2" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex flex-col items-start gap-xs">
                    <Label className="pt-lg">
                      Email will be sent every Monday for relevant news alerts
                    </Label>
                    <FormControl>
                      <TagInput
                        placeholder="Enter Emails..."
                        nonemptyPlaceholder="Add Email"
                        values={field.value}
                        setValues={field.onChange}
                        inputFieldPosition="top"
                        variant="outline"
                        delimiterList={[",", Delimiter.Enter]}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="col-span-2" />
                  </FormItem>
                )}
              />
              {listId && form.getValues("email").length > 0 && (
                <Button
                  type="button"
                  variant={"outline"}
                  onMouseDown={() => testMonitor()}
                >
                  {testing ? <LoadingIcon /> : "Send Test Email"}
                </Button>
              )}
            </CompactDialogBody>
            <Separator />
            <CompactDialogFooter className="justify-between">
              {initialData && (
                <Button
                  variant={"outlineDestructive"}
                  type="button"
                  onMouseDown={deleteMonitor}
                >
                  {deleting ? <LoadingIcon /> : "Remove News Monitor"}
                </Button>
              )}
              <Button type="submit" variant={"default"} className="ml-auto">
                {creating ? <LoadingIcon /> : "Save"}
              </Button>
            </CompactDialogFooter>
          </form>
        </FormProvider>
      </CompactDialogContent>
    </Dialog>
  );
}
