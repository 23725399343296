"use client";

import {
  type SearchListsRequest,
  type SearchListsResponse,
} from "@/app/api/list/search/route";
import { SearchSelect, SearchSelectItem } from "@tremor/react";
import { useEffect, useState } from "react";
import { Skeleton } from "../ui/skeleton";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ReturnIcon } from "../icons/return-icon";

interface ListSelectorProps {
  listId: string | undefined;
  setListId: (listId: string) => void;
  showCreateMode?: {
    listName: string;
    setListName: (listName: string) => void;
    creatingNewList: boolean;
    setCreatingNewList: (creatingNewList: boolean) => void;
  };
}

export function ListSelector({
  listId,
  setListId,
  showCreateMode,
}: ListSelectorProps) {
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [lists, setLists] = useState<
    {
      id: string;
      name: string;
      _count: { ContactsV2: number };
    }[]
  >([]);

  function fetchLists() {
    if (isFetchingList) return;
    setIsFetchingList(true);

    void fetch("/api/list/search", {
      method: "POST",
      body: JSON.stringify({ contactCount: true } as SearchListsRequest),
      headers: { "Content-Type": "application/json" },
    }).then(async (res) => {
      setLists(
        ((await res.json()) as SearchListsResponse).lists.map((list) => ({
          id: list.id,
          name: list.name,
          _count: { ContactsV2: list._count!.ContactsV2 ?? 0 },
        }))
      );
      setIsFetchingList(false);
    });
  }

  useEffect(() => {
    fetchLists();
  }, []);

  return !isFetchingList ? (
    showCreateMode ? (
      <div className="grid grid-cols-4 w-full mt-base">
        <Label className="col-span-1 h-full flex items-center justify-start text-sm w-full">
          List
        </Label>
        {showCreateMode.creatingNewList ? (
          <div className="col-span-3 flex flex-row gap-base items-center">
            <Input
              className="flex-grow text-xs py-base"
              placeholder="Enter a new list name..."
              value={showCreateMode.listName}
              onChange={(e) => showCreateMode.setListName(e.target.value)}
            />
            <Button
              variant="outline"
              onClick={() => showCreateMode.setCreatingNewList(false)}
            >
              <ReturnIcon />
            </Button>
          </div>
        ) : (
          <div className="col-span-3 mt-0">
            <SearchSelect
              className="w-full text-xs not-italic font-[525] mt-0"
              value={listId}
              placeholder="Select a List"
              onValueChange={(value) => {
                if (value === "new") {
                  showCreateMode.setCreatingNewList(true);
                } else {
                  setListId(value);
                  showCreateMode.setCreatingNewList(false);
                }
              }}
            >
              <SearchSelectItem
                key={"new"}
                value={"new"}
                className="w-full text-brand-500 text-xs h-8 not-italic font-[525]"
              >
                Create New List
              </SearchSelectItem>
              {lists.map((list) => (
                <SearchSelectItem
                  className="text-xs h-8 not-italic font-[525]"
                  key={list.id}
                  value={list.id}
                >
                  {list.name}
                  {/* ({list._count.ContactsV2} Contacts) */}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>
        )}
      </div>
    ) : (
      <SearchSelect
        className="text-xs not-italic h-8 font-[525] leading-[140%]"
        value={listId}
        placeholder="Select a List"
        onValueChange={(value) => setListId(value)}
      >
        {lists.map((list) => (
          <SearchSelectItem
            className="text-xs h-8 not-italic font-[525] leading-[140%]"
            key={list.id}
            value={list.id}
          >
            {list.name}
            {/* ({list._count.ContactsV2} Contacts) */}
          </SearchSelectItem>
        ))}
      </SearchSelect>
    )
  ) : (
    <div>
      <Skeleton className="h-8 w-full mt-xl" />
    </div>
  );
}
